body {
  background-color: black;
}

.App {
  /* text-align: center; */
  background: linear-gradient(180deg, rgb(0, 0, 0), rgb(0, 0, 0));
}

/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
*/
.all {
  padding: 10px 50px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f6c86e;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.header {
  display: block;
  background: rgb(0, 0, 0);
  padding: 10px;
  box-shadow: 1px 1px 10px rgba(255, 228, 194, 0.429);
  text-align: left;
}

h1,
h2 {
  color: #f6c86e;
  font-size: 45px;
  text-shadow: 2px 2px 0 #e8943c, 0 0 10px #ffa748;
  font-family: 'Common Pixel', sans-serif;
}

p {
  color: aliceblue;
  font-family: 'College Sans', sans-serif;
  font-size: 20px;
  display: inline;
}

.social {
  width: 30px;
}

a {
  margin: 10px;
}

.socialMedia {
  display: block;
  position: absolute;
  text-align: right;
  padding: 10px;
  top: 20px;
  right: 5px;

}

.div_logo_text {
  display: block;
  padding: 10px 0 5px 15px;
}

.logo_text {
  display: inline;
  width: 150px;
  text-align: left;
  margin: 10px;
}

button {
  background-color: #ffbe3b;
  padding: 20px;
  border: none;
  border-radius: 10px;
  box-shadow: 2px 2px rgb(255, 255, 255), 2px 2px 10px rgb(255, 255, 255);
  font-family: 'College Sans', sans-serif;
  font-size: 20px;
}

button:hover {
  box-shadow: 0 0 10px rgb(255, 255, 255);
}

.openseaButton {
  position: relative;
  right: 10px;
  margin-top: 10px;
}

.primero,
.segundo {
  margin: 50px;
}

.primero {
  text-align: left;
  display: inline;
}

.segundo {
  padding: 10px;
  text-align: right !important;
  position: absolute;
  top: 50px;
  right: 10px;
  margin-top: 85px;
}

.changeChain {
  margin: 10px 0 50px;
}

.secret {
  width: 550px;
  padding: 0 !important;
  margin: 0 !important;
}

.connect {
  margin-top: 50px;
  font-size: 25px;
}

.mintDiv {
  display: block;
  margin-top: 25px;
}

input[type="range"] {
  width: 250px;
}

.mintDiv button {
  display: block;
  margin-top: 10px;
}

.justMedia {
  display: none;
}

.MintCountDiv {
  margin: 10px 0;
}

table {
  border: 1px solid #ffbe3b;
  text-align: center;
}


th {
  color: white;
  text-align: center;
  border: 1px solid #ffbe3b;
  padding: 5px;
}

td {
  text-align: center;
  color: white;
  padding: 1px;
  border: 1px solid #ffbe3b;
}

td a {
  color: #ffbe3b
}

@media screen and (max-width: 1200px) {

  h1,
  h2 {
    font-size: 30px;
  }

  p {
    font-size: 20px;
  }

  .segundo {
    text-align: left;
    display: none;
  }

  .justMedia {
    display: block;
    margin: none;
  }

  .secret {
    margin: -100px;
  }

}